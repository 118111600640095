<template>
  <div>
    <optionscard :deliverParentNum="1"></optionscard>
    <!-- 面包屑（component文件夹里） -->
    <breadcrumb></breadcrumb>
    <div class="article-top">
      <!-- 1200宽度的div（主体区域） -->
      <div class="width1200"></div>
      <!-- 文章标题 -->
      <div class="article-title">{{ BzglArticleList.title }}</div>
      <!-- 文章时间 -->
      <div class="article-data">{{ BzglArticleList.issue_time }}</div>
    </div>
    <!-- 文章内容 -->
    <div class="article">
      <!-- 左边内容（文章） -->
      <div class="article-left">
        <div v-html="BzglArticleList.content"></div>
      </div>
      <!-- 文章右边（热门知识） -->
      <div class="hot-knowledge-div">
        <div class="hotKnowledgeDivTop">
          <!-- 热门知识字前面的蓝色小块 -->
          <div class="bluebulk"></div>
          <div class="hot-knowledge">热门知识</div>
        </div>
        <!-- 热门知识的推荐（4个） -->
        <div
          class="hot-article-div3"
          v-for="item in hotKnowledgeList"
          :key="item.id"
          @click="clickHotActivity(item.id)"
        >
          <!-- 热门知识标题 -->
          <div class="hot-article-title">{{ item.title }}</div>
          <!-- 热门知识内容 -->
          <div class="hot-article-body">{{item.content}}</div>
          <!-- 热门知识时间 -->
          <div class="hot-article-data">{{ item.issue_time }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import breadcrumb from '@/components/Breadcrumb.vue'
import optionscard from '@/components/optionscard.vue'
export default {
  components: {
    // 头部选项卡（首页 场馆展品 场馆活动 地震知识 参观服务
    optionscard,
    breadcrumb
  },
  data () {
    return {
      // 存放避震攻略热门知识数据
      hotKnowledgeList: [],
      // 存放避震攻略文章数据
      BzglArticleList: []
    }
  },
  created () {
    const id = this.$route.query.id
    this.getHotKnowledge(id)
    this.getBzglArticle(id)
  },
  methods: {
    // 获取避震攻略热门知识数据
    async getHotKnowledge (id) {
      const { res } = await this.$api.homepage.getHotKnowledge({ pid: 1 })
      res.forEach((i, index, array) => {
        // 如果url传的id和热门数据里其中一篇文章的数据一样 就把热门数据id一样的文章删掉
        if (i.id === Number(id)) {
          console.log('index', index)
          res.splice(index, 1)
        }
      })
      for (const i of res) {
        // 去除时间后面的时分秒
        if (i.issue_time.length > 10) {
          i.issue_time = i.issue_time.slice(0, 10)
        }
        //  删去内容里的img标签
        i.content = i.content.replace(/<img.*?><br\/?>/g, '')
        //  删去内容里的html标签和空格 (/<img.*?><br\/?>/g, '')
        const reg = /<\/?.+?\/?>/g
        i.content = i.content.replace(reg, '')
        i.content = i.content.replace(/<\/?p>/g, '')
        i.content = i.content.replace(/&nbsp;/gi, '')
        console.log('i.content', i.content)
      }
      console.log('避震攻略热门知识res', res)
      this.hotKnowledgeList = res
    },
    // 获取避震攻略文章数据
    async getBzglArticle (id) {
      const { res } = await this.$api.homepage.getBzglArticle({ id })
      console.log('避震攻略文章res', res)
      // 去除时间后面的时分秒
      if (res.issue_time.length > 10) {
        res.issue_time = res.issue_time.slice(0, 10)
      }
      this.BzglArticleList = res
    },
    // 热门知识文章详情跳转
    async clickHotActivity (id) {
      this.$router.push({ path: 'bzglthird', query: { id } })
    }
  },
  watch: {
    '$route.query.id' () {
      // 监听路由变化  // 组件第一次进入不会执行
      console.log('id 变化了', this.$route.query.id)
      this.getBzglArticle(this.$route.query.id)
      this.getHotKnowledge(this.$route.query.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.width1200 {
  width: 1200px;
  margin: 0 auto;
}

.article-top {
  border-bottom: 1px solid #eaeaea;
}

.article-title {
  width: 1200px;
  margin: 0 auto;
  font-size: 28px;
  margin-top: 40px;
  color: #333333;
}

.article-data {
  margin-left: 350px;
  margin: 18px 1440px 30px 350px ;
  text-align: center;
  font-size: 14px;
  color: #999999;
  margin-top: 18px;
  margin-bottom: 30px;
  background-color: #f7f7f7;
}

.article {
  width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.article-left {
  width: 795px;
  font-size: 15px;
  color: #606060;
  float: left;
  margin-bottom: 85px;
}

.hot-knowledge-div {
  width: 345px;
  margin-top: 20px;
  border: solid 1px #eaeaea;
  float: right;
  margin-bottom: 85px;
}

.hotKnowledgeDivTop {
  display: flex;
  margin-left: 20px;
  margin-top: 20px;
  height: 60px;
}

.article-picture {
  width: 580px;
  height: 362px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.bluebulk {
  width: 5px;
  height: 24px;
  background-color: #2e88e4;
  display: inline-block;
  margin-top: 5px;
}

.hot-knowledge {
  height: 24px;
  font-size: 24px;
  color: #333333;
  margin-left: 10px;
  margin-bottom: 40px;
}

.hot-article-div3 {
  border-top: 1px solid #eaeaea;
  margin-bottom: 30px;
  padding-top: 30px;
  margin: 0 18px;
}

.hot-article-div3:hover .hot-article-title {
  color: #2e88e4;
}

.hot-article-div3:hover {
  cursor: pointer;
}

.hot-article-title {
  width: 299px;
  font-size: 20px;
  color: #333333;
  margin-bottom: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hot-article-body {
  width: 289px;
  font-size: 16px;
  margin-bottom: 19px;
  color: #777777;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.hot-article-data {
  height: 25px;
  line-height: 25px;
  margin-bottom: 30px;
  font-size: 14px;
  color: #999999;
  background-color: #f7f7f7;
  margin-right: 200px;
  text-align: center;
}
</style>
